<template>
  <v-card flat>
    <v-card-title>Datos de terceros</v-card-title>
    <v-card-text>
      <v-row>
        <v-col cols="12" md="8">
          <v-row>
            <v-col cols="6">
              <v-switch
                  v-model="formData.subsidized"
                  hide-details
                  @change="checkCleanSubsidizedData"
              >
                <template #label>
                  <span class="subtitle-2">Bonificada (Fundae)</span>
                </template>
              </v-switch>
            </v-col>

            <v-col cols="6">
              <v-switch
                  v-model="formData.caes"
                  hide-details
                  disabled
              >
                <template #label>
                  <span class="subtitle-2">CAES</span>
                </template>
              </v-switch>
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="formData.afCode"
                :disabled="!formData.subsidized"
                dense
                label="Código AF"
              />
            </v-col>

            <v-col cols="6">
              <v-text-field
                v-model="formData.fundaeCode"
                :disabled="!formData.subsidized"
                dense
                label="Grupo"
              />
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" md="4">
          <v-switch v-model="formData.semicyuc" hide-details>
            <template #label>
              <span class="subtitle-2">Semycyuc</span>
            </template>
          </v-switch>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  props: {
    formData: { type: Object, required: true },
  },
  methods: {
    checkCleanSubsidizedData(active) {
      if (active) return;
      this.formData.afCode = null;
      this.formData.fundaeCode = null;
    },
  },
};
</script>

<style scoped></style>
